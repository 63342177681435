import { t } from "@lingui/macro";
import { Aperture, Trash, UploadSimple } from "@phosphor-icons/react";
import {
  Avatar,
  AvatarImage,
  buttonVariants,
  Input,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@rocket-resume/ui";
import { cn } from "@rocket-resume/utils";
import { motion } from "framer-motion";
import { useMemo, useRef } from "react";
import { z } from "zod";

import { useUploadImage } from "@/client/services/storage";
import { useResumeStore } from "@/client/stores/resume";

import { PictureOptions } from "./options";

export const PictureSection = () => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { uploadImage } = useUploadImage();

  const setValue = useResumeStore((state) => state.setValue);
  const picture = useResumeStore((state) => state.resume.data.basics.picture);

  const isValidUrl = useMemo(() => z.string().url().safeParse(picture.url).success, [picture.url]);

  const onSelectImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const response = await uploadImage(file);
      const url = response.data;

      setValue("basics.picture.url", url);
    }
  };

  const onAvatarClick = () => {
    if (isValidUrl) {
      setValue("basics.picture.url", "");
    } else {
      inputRef.current?.click();
    }
  };

  return (
    <div>
      <div className="flex flex-col gap-2 justify-center items-center group cursor-pointer" onClick={onAvatarClick}>
        <Avatar className="size-20 bg-secondary">
          <AvatarImage src={picture.url} />
        </Avatar>

        {isValidUrl ? (
          <div className="underline pointer-events-none inset-0 flex items-center justify-center rounded-full bg-background/30 opacity-100">
            Delete
          </div>
        ) : (
          <div className="underline pointer-events-none inset-0 flex items-center justify-center rounded-full bg-background/30 opacity-100">
            Edit
          </div>
        )}
      </div>

      <div className="flex w-full flex-col gap-y-1.5">
        <Label className="text-lg" htmlFor="basics.picture.url">{t`Picture`}</Label>
        <div className="flex items-center">
          <input ref={inputRef} hidden type="file" onChange={onSelectImage} />
          <div  className={isValidUrl ? "w-[90%]" : "w-[100%]"}>
            <Input
              id="basics.picture.url"
              placeholder="https://..."
              value={picture.url}
              className="h-12 border border-primary/20"
              onChange={(event) => {
                setValue("basics.picture.url", event.target.value);
              }}
            />
          </div>

          {isValidUrl && (
            <Popover>
              <PopoverTrigger asChild>
                <motion.button
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className={cn(buttonVariants({ size: "icon", variant: "ghost" }))}
                >
                  <Aperture />
                </motion.button>
              </PopoverTrigger>
              <PopoverContent className="w-[360px]">
                <PictureOptions />
              </PopoverContent>
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
};
